p {
  color: white;
}

.bg-lll {
  background-color: #1e1266;
}

.navbar-brand
{
  color: white !important;
  font-family: "Comfortaa" !important;
  font-weight: bold;
}

.nav-link
{
  color: white !important;
  font-weight: lighter !important;
  font-family: "Comfortaa" !important;
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: bold;
  src: url(./fonts/Comfortaa-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: lighter;
  src: url(./fonts/Comfortaa-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Comfortaa';
  src: url(./fonts/Comfortaa-Regular.ttf) format('truetype');
}

/* TODO: update to animate only when clicked
@media (prefers-reduced-motion: no-preference) {
  .App-Playground {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/